@import url(https://use.typekit.net/maw1dwo.css);
@import url(https://fonts.googleapis.com/css2?family=Work+Sans:wght@400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montaga&display=swap);
:root {
  --colour-black: #121212;
  --colour-white: #F2F2F2;

  --primary: #f0a76c;
  /* --primary: #7fcc3f; */
  --gray: #3E3E3E;
  --accent1: #75ECD6;
  --accent2: #E2A1EC;

  /* dynamic colours */
  --colour-body: var(--colour-black);
  --colour-text: var(--colour-white);
  --colour-text-secondary: var(--primary);

  --heading: proxima-nova, sans-serif;
  --subheading: proxima-nova, sans-serif;
  --content: "work sans", sans-serif;
  --mono: monospace;

  --bold: 800;
  --semi-bold: 600;
  --regular: 400;

  --linespace: 1.5;
  --padding: 1.65em;
  --gap: 1em;

  --real-vh: 100vh;

  --shadow: 0 0 10px 1px rgba(0, 0, 0, 0.3);

  --round: 100%;

  --time-proExpand: 0.7s cubic-bezier(0.95, 0.05, 0.795, 0.035);
  --time-proExpandQ: 0.4s ease;

  /* z-index */
  --z-module: 1;
  --z-shapes: 1;
  --z-focus: 48;
  --z-topbar: 49;
  --z-menugrab: 50;
  --z-main-menu: 51;
  --z-animOver: 99;
  --z-noise: 100;
}

:root.light {
  /* dynamic colours */
  --colour-body: var(--colour-white);
  --colour-text: var(--gray);
  --colour-text-secondary: var(--primary);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.2) !important;
  -webkit-focus-ring-color: rgba(255, 255, 255, 0) !important;
}

.poggers {
  position: absolute;
  z-index: 9;
  width: 100%;
  top: 0;
  left: 0;
  display: none;
}

::selection {
  background-color: #f0a76c;
  background-color: var(--primary);
  color: #F2F2F2;
  color: var(--colour-white);
}

html {
  font: 400 1em/1.5 "work sans", sans-serif;
  font: var(--regular) 1em/var(--linespace) var(--content);
  color: #F2F2F2;
  color: var(--colour-text);
}

body.disable {
  overflow: hidden;
}

main.disable {
  position: fixed;
  z-index: -1;
}

body.disable *, main.disable>* {
  pointer-events: none;
  /* overflow: hidden; */
}

body.disable .main-menu * {
  pointer-events: auto;
}

p {
  font: 400 1.25em/1.3 "work sans", sans-serif;
  font: var(--regular) 1.25em/1.3 var(--content);
  letter-spacing: -0.2px;
  /* padding-right: var(--padding); */
  color: #F2F2F2;
  color: var(--colour-text);
}

p.underline {
  text-decoration: underline;
}

h1 {
  font: 800 2.1em/1.1 proxima-nova, sans-serif;
  font: var(--bold) 2.1em/1.1 var(--heading);
  color: #F2F2F2;
  color: var(--colour-text);
  letter-spacing: -0.4px;
  font-family: "montaga";
}

h1.coloured {
  color: #f0a76c;
  color: var(--primary);
}

h1.thin {
  font-weight: 600;
  font-weight: var(--semi-bold);
}

h2 {
  font: 800 1.9em/1.15 proxima-nova, sans-serif;
  font: var(--bold) 1.9em/1.15 var(--heading);
  letter-spacing: -0.3px;
  color: #F2F2F2;
  color: var(--colour-text);
}

h2 span {
  font: 400 0.7em/1.5 proxima-nova, sans-serif;
  font: var(--regular) 0.7em/var(--linespace) var(--subheading);
  letter-spacing: -0.2px;
}

h3 {
  font: 600 1.15em/1 proxima-nova, sans-serif;
  font: var(--semi-bold) 1.15em/1 var(--heading);
  color: #F2F2F2;
  color: var(--colour-text);
}

h4 {
  font: 400 0.8em/1.5 proxima-nova, sans-serif;
  font: var(--regular) 0.8em/var(--linespace) var(--subheading);
  color: #F2F2F2;
  color: var(--colour-text);
  letter-spacing: -0.12px;
}

h4 span {
  font-weight: 800;
  font-weight: var(--bold);
  font-family: proxima-nova, sans-serif;
  font-family: var(--subheading);
}

h5 {
  font: 400 0.65em/1.5 proxima-nova, sans-serif;
  font: var(--regular) 0.65em/var(--linespace) var(--subheading);
  color: #F2F2F2;
  color: var(--colour-text);
  letter-spacing: -0.09px;
}

h6 {
  font: 400 0.8em/1.5 monospace;
  font: var(--regular) 0.8em/var(--linespace) var(--mono);
  color: #F2F2F2;
  color: var(--colour-text);
}

circle {
  mix-blend-mode: multiply;
}

a {
  color: #F2F2F2;
  color: var(--colour-text);
  text-decoration: none;
  outline: none !important;
  display: inline-block;
}

a:hover {
  text-decoration: none;
}

a p {
  border-bottom: solid 1px #F2F2F2;
  border-bottom: solid 1px var(--colour-text);
  padding-right: unset;
}

main {
  width: 100%;
  overflow: auto;
  overflow-x: hidden;
  background-color: #121212;
  background-color: var(--colour-black);
}

html, body {
  width: 100%;
  background-color: #121212;
  background-color: var(--colour-black);
}

/* div#root, div.app {
    overflow: hidden;
} */

.app::after {
  content: "";
  background-image: url(/static/media/grain3.7eeda0d7.png);
  opacity: 0.1;
  top: -30vh;
  left: -35vw;
  width: 200vw;
  position: fixed;
  z-index: 100;
  z-index: var(--z-noise);
  height: 200vh;
  -webkit-animation: noise 1s steps(2) infinite;
          animation: noise 1s steps(2) infinite;
  will-change: transform;
  background-position: 50%;
  pointer-events: none;
}

@-webkit-keyframes noise {
  0% {
    -webkit-transform: translate3d(0, 9rem, 0);
            transform: translate3d(0, 9rem, 0)
  }

  10% {
    -webkit-transform: translate3d(-1rem, -4rem, 0);
            transform: translate3d(-1rem, -4rem, 0)
  }

  20% {
    -webkit-transform: translate3d(-8rem, 2rem, 0);
            transform: translate3d(-8rem, 2rem, 0)
  }

  30% {
    -webkit-transform: translate3d(9rem, -9rem, 0);
            transform: translate3d(9rem, -9rem, 0)
  }

  40% {
    -webkit-transform: translate3d(-2rem, 7rem, 0);
            transform: translate3d(-2rem, 7rem, 0)
  }

  50% {
    -webkit-transform: translate3d(-9rem, -4rem, 0);
            transform: translate3d(-9rem, -4rem, 0)
  }

  60% {
    -webkit-transform: translate3d(2rem, 6rem, 0);
            transform: translate3d(2rem, 6rem, 0)
  }

  70% {
    -webkit-transform: translate3d(7rem, -8rem, 0);
            transform: translate3d(7rem, -8rem, 0)
  }

  80% {
    -webkit-transform: translate3d(-9rem, 1rem, 0);
            transform: translate3d(-9rem, 1rem, 0)
  }

  90% {
    -webkit-transform: translate3d(6rem, -5rem, 0);
            transform: translate3d(6rem, -5rem, 0)
  }

  to {
    -webkit-transform: translate3d(-7rem, 0, 0);
            transform: translate3d(-7rem, 0, 0)
  }
}

@keyframes noise {
  0% {
    -webkit-transform: translate3d(0, 9rem, 0);
            transform: translate3d(0, 9rem, 0)
  }

  10% {
    -webkit-transform: translate3d(-1rem, -4rem, 0);
            transform: translate3d(-1rem, -4rem, 0)
  }

  20% {
    -webkit-transform: translate3d(-8rem, 2rem, 0);
            transform: translate3d(-8rem, 2rem, 0)
  }

  30% {
    -webkit-transform: translate3d(9rem, -9rem, 0);
            transform: translate3d(9rem, -9rem, 0)
  }

  40% {
    -webkit-transform: translate3d(-2rem, 7rem, 0);
            transform: translate3d(-2rem, 7rem, 0)
  }

  50% {
    -webkit-transform: translate3d(-9rem, -4rem, 0);
            transform: translate3d(-9rem, -4rem, 0)
  }

  60% {
    -webkit-transform: translate3d(2rem, 6rem, 0);
            transform: translate3d(2rem, 6rem, 0)
  }

  70% {
    -webkit-transform: translate3d(7rem, -8rem, 0);
            transform: translate3d(7rem, -8rem, 0)
  }

  80% {
    -webkit-transform: translate3d(-9rem, 1rem, 0);
            transform: translate3d(-9rem, 1rem, 0)
  }

  90% {
    -webkit-transform: translate3d(6rem, -5rem, 0);
            transform: translate3d(6rem, -5rem, 0)
  }

  to {
    -webkit-transform: translate3d(-7rem, 0, 0);
            transform: translate3d(-7rem, 0, 0)
  }
}

;

ul {
  list-style: none;
}

/* ________________ BTN ___________________ */
button {
  border: none;
  cursor: pointer;
  outline: none !important;
}

button.standard {
  padding: calc(1.65em / 2) calc(1.65em / 2);
  padding: calc(var(--padding) / 2) calc(var(--padding) / 2);
  background: var(--peach);
  color: #F2F2F2;
  color: var(--colour-white);
}

button.text {
  font: 600 1em/0.4 proxima-nova, sans-serif;
  font: var(--semi-bold) 1em/0.4 var(--heading);
  padding: calc(1.65em / 4) 0;
  padding: calc(var(--padding) / 4) 0;
  background: transparent;
  color: #E2A1EC;
  color: var(--accent2);
  display: inline-block;
  text-align: left;
  border-bottom: solid 1px #E2A1EC;
  border-bottom: solid 1px var(--accent2);
}

button.text a {
  color: inherit;
}

button.text:hover {
  /* text-decoration: underline; */
  border-bottom: solid 2px #E2A1EC;
  border-bottom: solid 2px var(--accent2);
}

button.icon {
  background: none;
  width: 2em;
  height: 2em;
  padding: 0.4em;
}

button.icon img {
  width: 100%;
}

button.icon.round {
  border-radius: 100%;
  border: solid rgba(255,255,255,0.3) 1px;
  display: grid;
  align-items: center;
  justify-items: center;
  width: 3em;
  height: 3em;
  padding: 0.6em
}

button.icon.round:hover {
  background-color: rgba(255,255,255,0.2);
}
button.icon.round[aria-disabled="true"]:hover {
  background-color: unset
}

/* ________________ TOPBAR __________________ */

nav.topbar > :first-child {
  position: fixed;
  top: 1.65em;
  top: var(--padding);
  left: calc(1.65em + 1.2em);
  left: calc(var(--padding) + 1.2em);
  width: 2em;
  z-index: 49;
  z-index: var(--z-topbar);
  mix-blend-mode: exclusion;
}

nav.topbar img {
  width: 100%;
}

nav.topbar .pages {
  position: fixed;
  top: calc(1.65em - 0.2em);
  top: calc(var(--padding) - 0.2em);
  left: calc(1.65em * 4);
  left: calc(var(--padding) * 4);
  height: 2.2em;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-items: right;
  grid-gap: calc(1em * 1.5);
  gap: calc(1em * 1.5);
  grid-gap: calc(var(--gap) * 1.5);
  gap: calc(var(--gap) * 1.5);
  background-color: #121212;
  background-color: var(--colour-black);
  z-index: 49;
  z-index: var(--z-topbar);
  padding: 0 1em;
  border-radius: 3em;
  border: solid 1px rgb(50,50,50);
}

nav.topbar .pages .selected {
  pointer-events: none;
  border-bottom: solid white 1px;
}

/* ________________ MENUGRAB ___________________ */

div.menu {
  position: fixed;
  /* overflow: hidden; */
  top: 0;
  right: 0;
  width: 2.5em;
  height: 2.5em;
  display: grid;
  justify-items: center;
  align-items: center;
  z-index: 50;
  z-index: var(--z-menugrab);
}

div.menu.active {
  width: 100vw !important;
  height: 100vh !important;
  transition: 0.5s ease;
}

div.menu.active img {
  display: none;
}

div.menu.active .menu-grab {
  border-radius: 0;
  transition: inherit;
}

div.menu .menu-grab {
  border-radius: 100%;
  border-radius: var(--round);
  width: 200%;
  height: 200%;
  top: -50%;
  right: -50%;
  position: relative;
  background-color: #f0a76c;
  background-color: var(--primary);
}

div.menu img {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  position: absolute;
  z-index: 1;
  width: 2em;
  bottom: 1.2em;
  left: 0.85em;
}

/* ________________ MAINMENU ___________________ */

.main-menu {
  z-index: 51;
  z-index: var(--z-main-menu);
  position: fixed;
  padding: calc(1.65em + 1.2em);
  padding: calc(var(--padding) + 1.2em);
  --colour-text: var(--colour-black);
  height: 100vh;
  top: 0;
  width: 100%;
}

.main-menu .inner-menu {
  display: grid;
  grid-template-rows: -webkit-max-content -webkit-max-content auto -webkit-min-content;
  grid-template-rows: max-content max-content auto min-content;
  grid-gap: calc(1.65em * 2.25);
  gap: calc(1.65em * 2.25);
  grid-gap: calc(var(--padding) * 2.25);
  gap: calc(var(--padding) * 2.25);
  height: 100%;
}

.main-menu .inner-menu>p {
  padding-right: calc(1.65em * 2);
  padding-right: calc(var(--padding) * 2);
}

.main-menu .header {
  display: grid;
  grid-template-columns: auto -webkit-min-content;
  grid-template-columns: auto min-content;
}

.main-menu .close-icon {
  width: 1.2em;
  cursor: pointer;
}

.main-menu .logo {
  -webkit-filter: invert(1);
          filter: invert(1);
}

.main-menu nav {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.main-menu nav div {
  display: grid;
  grid-gap: 1em;
  gap: 1em;
  grid-gap: var(--gap);
  gap: var(--gap);
  grid-template-rows: -webkit-min-content -webkit-min-content;
  grid-template-rows: min-content min-content;
}

.main-menu nav img {
  width: calc(100% + calc(1.65em + 1.2em));
  width: calc(100% + calc(var(--padding) + 1.2em));
  margin-right: calc(calc(1.65em * -1) + 1.2em);
  margin-right: calc(calc(var(--padding) * -1) + 1.2em);
  margin-top: calc(1em * -1);
  margin-top: calc(var(--gap) * -1);

}

.main-menu .foot {
  display: grid;
  position: fixed;
  bottom: calc(1.65em * 2);
  bottom: calc(var(--padding) * 2);
}

.main-menu .foot .social-icons {
  margin-top: calc(1.65em * 1.6);
  margin-top: calc(var(--padding) * 1.6);
  -webkit-filter: brightness(0);
          filter: brightness(0);
}

/* ________________ FUNCTION __________________ */

div.tags {
  display: flex;
  flex-direction: row;
  width: auto;
  overflow-x: auto;
}

div.tags h4 {
  margin-right: 1em;
  margin-right: var(--gap);
  text-transform: capitalize;
}

.em-sizer {
  visibility: hidden;
  width: 100em;
  position: fixed;
  top: -2em;
  pointer-events: none;
}

div.fab {
  position: fixed;
  z-index: var(--z-fab);
  width: 3.2em;
  height: 3.2em;
  border-radius: 100%;
  border-radius: var(--round);
  bottom: calc(1.65em / 1.5);
  bottom: calc(var(--padding) / 1.5);
  right: calc(1.65em / 1.5);
  right: calc(var(--padding) / 1.5);
  background-color: var(--pale-yellow);
  cursor: pointer;
}

div.fab img {
  padding: 0.75em;
  width: 100%;
  z-index: 1;
  -webkit-filter: invert(1);
          filter: invert(1);
  opacity: 0.6;
}

div.fab .hover {
  background-color: var(--pale-yellow);
  color: #3E3E3E;
  color: var(--gray);
  border-radius: 0.2em;
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%) scale(0);
          transform: translateY(-50%) scale(0);
  padding: 0.2em 0.5em;
  transition: 0.4s ease;
  z-index: -1;
  opacity: 0;
}

/* ________________ UTILITY __________________ */

.photograph {
  background-position: center;
  background-size: cover;
  width: 80%;
  height: 85vw;
  max-width: 30em;
  max-height: 34em;
  margin: 0 auto;
  margin-top: 1em;
  -webkit-transform: rotate(2deg);
          transform: rotate(2deg);
  transition: width 0.7s cubic-bezier(0.95, 0.05, 0.795, 0.035), height 0.7s cubic-bezier(0.95, 0.05, 0.795, 0.035);
  transition: width var(--time-proExpand), height var(--time-proExpand);
}

.photograph-sub {
  width: 55%;
  right: 0;
  top: 65vw;
  margin-right: 5vw;
  margin-bottom: calc(1.65em * -0.5);
  margin-bottom: calc(var(--padding) * -0.5);
  -webkit-transform: rotate(-6deg);
          transform: rotate(-6deg);
  z-index: 1;
  position: absolute;
  background-position: center;
  background-size: cover;
  height: 30vw;
  max-height: 12em;
  max-width: 18em;
}

.photograph-overlap {
  position: relative;
  display: inline-block;
  justify-self: center;
  width: 100%;
  margin: 1.65em 0;
  margin: var(--padding) 0;
}

/* ________________ TILE STYLE __________________ */

.tile, .all-projects {
  padding: 1.65em;
  padding: var(--padding);
  background-color: #121212;
  background-color: var(--colour-black);
}

.tile.intro, .tile.projects {
  background-image: url(/static/media/paper.ff7c768c.png);
  background-size: 100% 100%;
  background-position: center;
  min-height: 100vh;
}

.tile.invert, .focus .invert {
  --colour-text: var(--gray);
}

section.projects {
  --colour-text: var(--colour-white);
  --colour-text-secondary: var(--pale-yellow);
}

section.projects .date {
  --colour-text-secondary: var(--light-blue);
}

section.projects a {
  align-self: end;
}

footer.tile.footer {
  background-color: #121212;
  background-color: var(--colour-black);
  display: grid;
  grid-gap: calc(1em * 2);
  gap: calc(1em * 2);
  grid-gap: calc(var(--gap) * 2);
  gap: calc(var(--gap) * 2);
  grid-template-columns: -webkit-max-content auto;
  grid-template-columns: max-content auto;
  align-items: center;
  padding: calc(1.65em * 2);
  padding: calc(var(--padding) * 2);
  justify-items: right;
}

footer.tile.footer div:first-child {
  position: relative;
  justify-self: left;
}

footer.tile.footer div:first-child h5 {
  position: absolute;
  left: 2.4em;
  top: 0;
}

/* ________________ LAYOUTS __________________ */

.logo {
  width: 2em;
}

section.layout {
  display: grid;
  grid-gap: 1.65em;
  gap: 1.65em;
  grid-gap: var(--padding);
  gap: var(--padding);
}

section.tile.intro {
  min-height: 100vh;
  min-height: var(--real-vh);
  background-color: #121212;
  background-color: var(--colour-black);
  padding: calc(1.65em * 1.7);
  padding: calc(var(--padding) * 1.7);
  padding-top: calc(1.65em * 3.6);
  padding-top: calc(var(--padding) * 3.6);
  position: relative;
  display: grid;
  grid-template-rows: -webkit-max-content auto;
  grid-template-rows: max-content auto;
}

section.tile.intro .hero {
  height: 100%;
  display: grid;
}

section.tile.intro .hero .hero-type {
  align-self: end;
  display: grid;
  grid-template-rows: auto auto;
  grid-gap: calc(1.65em * 2);
  gap: calc(1.65em * 2);
  grid-gap: calc(var(--padding) * 2);
  gap: calc(var(--padding) * 2);
  margin-bottom: calc(1.65em * 2);
  margin-bottom: calc(var(--padding) * 2);
}

section.tile.intro .hero a {
  align-self: center;
}

section.tile.intro .hero img.arrow {
  /* display: none; */
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  width: 2em;
  cursor: pointer;
}

section.tile.intro .hero-text h1 {
  color: #F2F2F2;
  color: var(--colour-white);
  font-size: 2.6em;
}

section.tile.intro .hero-sub {
  color: #F2F2F2;
  color: var(--colour-white);
  display: grid;
  grid-template-columns: auto -webkit-max-content;
  grid-template-columns: auto max-content;
  grid-gap: 1em;
  gap: 1em;
  grid-gap: var(--gap);
  gap: var(--gap);
}

section.tile.intro .shape-break {
  display: grid;
  grid-template-columns: repeat(3, -webkit-min-content);
  grid-template-columns: repeat(3, min-content);
  grid-gap: 1em;
  gap: 1em;
  grid-gap: var(--gap);
  gap: var(--gap);
  align-items: center;
  margin-bottom: calc(1.65em * 1.6);
  margin-bottom: calc(var(--padding) * 1.6);
}

section.tile.intro img {
  width: 100%;
}

section.tile.jump {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1em;
  gap: 1em;
  grid-gap: var(--gap);
  gap: var(--gap);
}

section.social-icons {
  display: grid;
  grid-template-columns: repeat(2, -webkit-min-content);
  grid-template-columns: repeat(2, min-content);
  grid-gap: 1.65em;
  gap: 1.65em;
  grid-gap: var(--padding);
  gap: var(--padding);
  align-items: center;
  justify-items: center;
}

section.social-icons img {
  width: 2.4em;
}

section.projects {
  display: grid;
  grid-gap: 1em;
  gap: 1em;
  grid-gap: var(--gap);
  gap: var(--gap);
  padding-top: calc(1.65em * 1.6);
  padding-top: calc(var(--padding) * 1.6);
  padding-bottom: 1.65em;
  padding-bottom: var(--padding);
}

section.projects.expand {
  background-color: #F2F2F2;
  background-color: var(--colour-white);
  transition: background-color 0.7s cubic-bezier(0.95, 0.05, 0.795, 0.035);
  transition: background-color var(--time-proExpand);
  height: 120vh;
}

section.projects .project-head {
  padding: 0 1.65em;
  padding: 0 var(--padding);
  margin-top: 1.65em;
  margin-top: var(--padding);
  /* position: absolute;
    left: 0;
    bottom: calc(var(--padding) * -1); */
}

section.projects .project-head div * {
  display: inline-block;
}

section.projects .project-head nav {
  display: grid;
  grid-template-columns: -webkit-min-content -webkit-min-content;
  grid-template-columns: min-content min-content;
  grid-gap: 1em;
  gap: 1em;
  grid-gap: var(--gap);
  gap: var(--gap);
}

section.projects .project-head nav img.disabled {
  opacity: 0.3;
  cursor: default !important;
}

section.projects .project-head nav img {
  cursor: w-resize;
}

section.projects .project-head nav button:last-child img {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  cursor: e-resize;
}

section.projects .projects-row-container {
  width: calc(100vw - calc(1.65em * 2));
  width: calc(100vw - calc(var(--padding) * 2));
  position: relative;
  margin: auto 0;
}

section.projects .projects-row {
  display: flex;
  width: auto;
  flex-direction: row;
  margin: 0 calc(1.65em * -1);
  margin: 0 calc(var(--padding) * -1);
  overflow: hidden;
  transition: margin 0.4s ease;
  transition: margin var(--time-proExpandQ);
}

section.projects.expand .projects-row {
  margin-top: -5.28em;
  transition: margin 0.7s cubic-bezier(0.95, 0.05, 0.795, 0.035);
  transition: margin var(--time-proExpand);
}

section.projects .projects-row::after {
  content: '';
  min-width: calc(1.65em / 2);
  min-width: calc(var(--padding) / 2);
}

section.projects .projects-row .project-tile {
  width: 100vw;
  height: 100%;
  flex: 0 0 auto;
  /* margin-right: calc(var(--padding) / 2); */
  display: grid;
  grid-gap: 1em;
  gap: 1em;
  grid-gap: var(--gap);
  gap: var(--gap);
  grid-template-rows: -webkit-min-content auto;
  grid-template-rows: min-content auto;
  transition: opacity 0.3s ease, background-color 0.1s ease;
}

section.projects .projects-row .project-tile.off {
  opacity: 0 !important;
  pointer-events: none;
}

section.projects .projects-row .project-tile:last-child {
  margin-right: 0;
}

section.projects.expand .projects-row .project-tile:not(.off) .project-info {
  opacity: 0;
  transition: opacity 0.7s cubic-bezier(0.95, 0.05, 0.795, 0.035);
  transition: opacity var(--time-proExpand);
}

section.projects.expand .projects-row .project-tile:not(.off) .project-image {
  opacity: 1;
  margin: 0;
  width: 100%;
  height: 60vh;
  max-width: 100%;
  max-height: 60vh;
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  transition: margin 0.7s cubic-bezier(0.95, 0.05, 0.795, 0.035), width 0.7s cubic-bezier(0.95, 0.05, 0.795, 0.035), height 0.7s cubic-bezier(0.95, 0.05, 0.795, 0.035), max-width 0.7s cubic-bezier(0.95, 0.05, 0.795, 0.035), max-height 0.7s cubic-bezier(0.95, 0.05, 0.795, 0.035), -webkit-transform 0.7s cubic-bezier(0.95, 0.05, 0.795, 0.035);
  transition: margin 0.7s cubic-bezier(0.95, 0.05, 0.795, 0.035), width 0.7s cubic-bezier(0.95, 0.05, 0.795, 0.035), height 0.7s cubic-bezier(0.95, 0.05, 0.795, 0.035), transform 0.7s cubic-bezier(0.95, 0.05, 0.795, 0.035), max-width 0.7s cubic-bezier(0.95, 0.05, 0.795, 0.035), max-height 0.7s cubic-bezier(0.95, 0.05, 0.795, 0.035);
  transition: margin 0.7s cubic-bezier(0.95, 0.05, 0.795, 0.035), width 0.7s cubic-bezier(0.95, 0.05, 0.795, 0.035), height 0.7s cubic-bezier(0.95, 0.05, 0.795, 0.035), transform 0.7s cubic-bezier(0.95, 0.05, 0.795, 0.035), max-width 0.7s cubic-bezier(0.95, 0.05, 0.795, 0.035), max-height 0.7s cubic-bezier(0.95, 0.05, 0.795, 0.035), -webkit-transform 0.7s cubic-bezier(0.95, 0.05, 0.795, 0.035);
  transition: margin var(--time-proExpand), width var(--time-proExpand), height var(--time-proExpand), max-width var(--time-proExpand), max-height var(--time-proExpand), -webkit-transform var(--time-proExpand);
  transition: margin var(--time-proExpand), width var(--time-proExpand), height var(--time-proExpand), transform var(--time-proExpand), max-width var(--time-proExpand), max-height var(--time-proExpand);
  transition: margin var(--time-proExpand), width var(--time-proExpand), height var(--time-proExpand), transform var(--time-proExpand), max-width var(--time-proExpand), max-height var(--time-proExpand), -webkit-transform var(--time-proExpand);
}

section.projects.expand .projects-row .project-tile:not(.off) .project-image-sub, section.projects.expand .projects-row .project-tile:not(.off) .project-image-sub2 {
  opacity: 0;
  transition: opacity 0.7s cubic-bezier(0.95, 0.05, 0.795, 0.035);
  transition: opacity var(--time-proExpand);
}

section.projects .projects-row .project-tile .project-images {
  position: relative;
  padding-bottom: calc(1.65em * 2.5);
  padding-bottom: calc(var(--padding) * 2.5);
  cursor: pointer;
  max-width: 60em;
}

section.projects .projects-row .project-tile .project-image {
  background-position: center;
  background-size: cover;
  width: calc(95% - calc(1.65em * 4));
  width: calc(95% - calc(var(--padding) * 4));
  height: 44vh;
  margin: 0 calc(1.65em * 2);
  margin: 0 calc(var(--padding) * 2);
  margin-top: 1em;
  -webkit-transform: rotate(2deg);
          transform: rotate(2deg);
  transition: width 0.7s cubic-bezier(0.95, 0.05, 0.795, 0.035), height 0.7s cubic-bezier(0.95, 0.05, 0.795, 0.035);
  transition: width var(--time-proExpand), height var(--time-proExpand);
}

section.projects .projects-row .project-tile .project-image-sub {
  width: 55%;
  right: 0;
  bottom: 0;
  margin-right: calc(1.65em * 1.7);
  margin-right: calc(var(--padding) * 1.7);
  /* margin-top: calc(var(--padding) * -4.3); */
  margin-bottom: calc(1.65em * 1.7);
  margin-bottom: calc(var(--padding) * 1.7);
  -webkit-transform: rotate(-6deg);
          transform: rotate(-6deg);
  z-index: 1;
  position: absolute;
  background-position: center;
  background-size: cover;
  height: 30vw;
  max-height: 10em;
  max-width: 15em;
}

.project-image-sub2 {
  border-radius: 100%;
  background-position: center;
  background-size: cover;
  z-index: 1;
  width: 13vw;
  height: 13vw;
}

section.projects .projects-row .project-tile .project-image-sub2 {
  width: 13vw;
  height: 13vw;
  right: 0;
  top: 0;
  margin-right: 20%;
  margin-top: calc(1.65em * 1.3);
  margin-top: calc(var(--padding) * 1.3);
  z-index: 1;
  position: absolute;
  display: none;
  max-width: 16em;
  max-height: 16em;
}

section.projects .projects-row .project-tile .project-info {
  padding: 0 calc(1.65em * 2);
  padding: 0 calc(var(--padding) * 2);
  opacity: 1;
  transition: opacity 0.4s ease;
  transition: opacity var(--time-proExpandQ);
  display: grid;
  grid-gap: 1em;
  gap: 1em;
  grid-gap: var(--gap);
  gap: var(--gap);
}


section.projects .projects-row .project-tile .project-info .desc, section.projects .projects-row .project-tile .project-info .extra-info {
  display: none;
}

section.projects .projects-row .project-tile .project-meta {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 1em;
  gap: 1em;
  grid-gap: var(--gap);
  gap: var(--gap);
}

section.projects .projects-row .project-tile .project-meta .tags {
  grid-column: span 2;
}

section.projects .projects-row .project-tile .project-info .expand-project {
  margin-top: calc(1em / 2);
  margin-top: calc(var(--gap) / 2);
}

section.projects .projects-row .project-tile .project-info .expand-project h3 {
  color: #f0a76c;
  color: var(--primary);
  cursor: pointer;
}

section.projects .projects-row .project-tile .project-info .expand-project span {
  color: #E2A1EC;
  color: var(--accent2);
}


footer.footer div:first-child {
  display: grid;
  grid-template-columns: auto -webkit-max-content;
  grid-template-columns: auto max-content;
  align-items: center;
  grid-gap: 1em;
  gap: 1em;
  grid-gap: var(--gap);
  gap: var(--gap);
}

.shapes {
  width: 100vw;
  position: absolute;
  overflow: hidden;
  height: 100vh;
  left: 0;
  top: 0;
  pointer-events: none;
  -webkit-user-select: none;
          user-select: none;
  display: none;
}

.square-shape {
  width: 16em;
  height: 18em;
  background-color: transparent;
  border: 1px solid #75ECD6;
  border: 1px solid var(--accent1);
  position: absolute;
  z-index: 1;
  z-index: var(--z-shapes);
  -webkit-transform: rotate(30deg);
          transform: rotate(30deg);
  top: 25em;
  right: -8em;
}

.circle-shape {
  width: 16em;
  height: 16em;
  background-color: transparent;
  border: 1px solid #E2A1EC;
  border: 1px solid var(--accent2);
  position: absolute;
  z-index: 1;
  z-index: var(--z-shapes);
  top: 55em;
  left: -8em;
  border-radius: 100%;
  border-radius: var(--round);
}

/* ________________ ME __________________ */
.tile.me {
  background-color: #F2F2F2;
  background-color: var(--colour-white);
}

.me-img {
  margin-top: calc(1.65em * -1);
  margin-top: calc(var(--padding) * -1);
}

.me-img img {
  width: 100%;
}

.me .hero-text {
  margin-top: -7.8em;
  padding-left: 1.2em;
}

.me .layout p {
  max-width: 60em;
}

ul.expand-list li {
  border-top: solid 1px #3E3E3E;
  border-top: solid 1px var(--gray);
  position: relative;
  padding: 1.65em;
  padding: var(--padding);
  display: grid;
  padding-bottom: 0;
}

ul.expand-list li h6 {
  position: absolute;
  top: 1em;
  top: var(--gap);
  left: 0;
}

ul.expand-list li h3 {
  font-size: 1em;
}

ul.expand-list li .expand-info {
  padding-bottom: 1.65em;
  padding-bottom: var(--padding);
}

ul.expand-list li:hover {}

ul.expand-list li .expand-body {
  overflow: hidden;
  transition: max-height 0.5s ease;
  max-height: 0;
}

ul.expand-list .expand-body article {
  display: grid;
}

ul.expand-list li .expand-body.active {
  display: block;
}

ul.expand-list li .inner-expand-body {
  padding-bottom: 1.65em;
  padding-bottom: var(--padding);
  display: grid;
  grid-gap: calc(1em * 1.5);
  gap: calc(1em * 1.5);
  grid-gap: calc(var(--gap) * 1.5);
  gap: calc(var(--gap) * 1.5);
}

ul.expand-list li .inner-expand-body .header {
  display: grid;
  grid-template-columns: -webkit-min-content auto;
  grid-template-columns: min-content auto;
  grid-gap: 1em;
  gap: 1em;
  grid-gap: var(--gap);
  gap: var(--gap);
  align-items: center;
  padding-top: 1em;
  padding-top: var(--gap);
}

ul.expand-list li .inner-expand-body .header div, .tile.intro div.shape-break div {
  border: solid 1px #E2A1EC;
  border: solid 1px var(--accent2);
  width: 0.8em;
  height: 0.8em;
  border-radius: 100%;
  border-radius: var(--round);
}

ul.expand-list li .inner-expand-body>div:first-of-type div, .tile.intro div.shape-break div:first-of-type {
  border-radius: unset;
}

ul.expand-list li .inner-expand-body>div:last-of-type div, .tile.intro div.shape-break div:last-of-type {
  border-radius: unset;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  margin-left: 0.2em;
  width: 0.7em;
  height: 0.7em;
}


/* ________________ BTM POP __________________ */

.bottom-pop-bg {
  position: fixed;
  left: 0;
  right: 0;
  width: 100%;
  z-index: calc(var(--z-fab) - 2);
  background-color: rgba(0, 0, 0, 0.3);
  bottom: 100vh;
  height: 100vh;
  opacity: 0;
  transition: opacity 0.6s ease;
}

.bottom-pop-bg.open {
  opacity: 1;
  bottom: 0;
}

.bottom-pop {
  position: fixed;
  left: 0;
  right: 0;
  bottom: -40vh;
  height: 40vh;
  width: 100%;
  background-color: #F2F2F2;
  background-color: var(--colour-white);
  --colour-text: var(--colour-black);
  z-index: calc(var(--z-fab) - 1);
  padding: 1.65em;
  padding: var(--padding);
  transition: bottom 0.6s ease;

}

.bottom-pop.open {
  bottom: 0;
}

/* ________________ FOCUS __________________ */

.focus-anim-in {
  position: fixed;
  z-index: 99;
  z-index: var(--z-animOver);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
}

.focus a {
  color: #f0a76c;
  color: var(--colour-text-secondary);
  text-decoration: underline;
}

.focus .text a {
  text-decoration: none;
  --colour-text: var(--primary);
}

.focus .tile {
  padding: 1.65em;
  padding: var(--padding);
}

.focus .link-out {
  display: flex;
  flex-direction: column;
  grid-gap: calc(1em / 3);
  gap: calc(1em / 3);
  grid-gap: calc(var(--gap) / 3);
  gap: calc(var(--gap) / 3);
}

.focus .link-out .title {
  display: grid;
  grid-template-columns: -webkit-max-content -webkit-max-content;
  grid-template-columns: max-content max-content;
  align-items: baseline;
  grid-gap: calc(1em / 4);
  gap: calc(1em / 4);
  grid-gap: calc(var(--gap) / 4);
  gap: calc(var(--gap) / 4);
  margin-top: 1.65em;
  margin-top: var(--padding);
  margin-bottom: 1em;
  margin-bottom: var(--gap);
}

.focus .link-out h4 {
  align-self: start;
}

.focus {
  background-color: #F2F2F2;
  background-color: var(--colour-white);
  overflow: auto;
  z-index: 48;
  z-index: var(--z-focus);
  position: relative;
  --padding: 2.5em;
}

.focus h1 {
  color: #121212;
  color: var(--colour-black);
}

.focus .hero-image {
  width: 100%;
  /* margin: 0 calc(var(--padding) * -1); */
  height: 60vh;
  background-position: center;
  background-size: cover;
}

.focus img {
  width: 100%;
}

.focus article>* {
  margin-bottom: 1em;
  margin-bottom: var(--gap);
}

.inner-focus {
  position: relative;
}

.inner-inner-focus {
  background-color: #F2F2F2;
  background-color: var(--colour-white);
  display: grid;
}

.focus .focus-hero {
  display: grid;
  grid-template-rows: auto auto;
}

.focus .focus-header {
  grid-template-columns: auto -webkit-min-content;
  grid-template-columns: auto min-content;
  display: grid;
  min-height: 40vh;
  grid-gap: calc(1em * 3.5) 1em;
  gap: calc(1em * 3.5) 1em;
  grid-gap: calc(var(--gap) * 3.5) var(--gap);
  gap: calc(var(--gap) * 3.5) var(--gap);
  background-color: #F2F2F2;
  background-color: var(--colour-white);
}

.focus ul.info-list li {
  display: grid;
  grid-gap: 1em;
  gap: 1em;
  grid-gap: var(--gap);
  gap: var(--gap);
  grid-template-columns: -webkit-max-content auto;
  grid-template-columns: max-content auto;
  padding: calc(1em / 3) 0;
  padding: calc(var(--gap) / 3) 0;
  text-transform: capitalize;
}

.focus .focus-info {
  display: grid;
  grid-column: span 2;
  grid-gap: calc(1em * 3.5);
  gap: calc(1em * 3.5);
  grid-gap: calc(var(--gap) * 3.5);
  gap: calc(var(--gap) * 3.5);
}

.focus .focus-info .project-image-sub2 {
  display: none;
}

.focus .focus-body {
  background-color: #121212;
  background-color: var(--colour-black);
}

.focus .focus-body h1 {
  color: #F2F2F2;
  color: var(--colour-text);
}

.focus .focus-body img {
  margin-right: calc(1.65em * -1);
  margin-right: calc(var(--padding) * -1);
  /* width: calc(100% + var(--padding)); */
}

.keep-scrolling {
  position: absolute;
  z-index: calc(48 + 1);
  z-index: calc(var(--z-focus) + 1);
  width: 100%;
  height: 300px;
  margin-top: -150px;
  display: grid;
  justify-items: right;
  pointer-events: none;
  mix-blend-mode: exclusion;
  overflow: hidden;
}

.keep-scrolling .center-circle {
  background-color: #f0a76c;
  background-color: var(--primary);
  border-radius: 100%;
  border-radius: var(--round);
  width: 5em;
  height: 5em;
}

.keep-scrolling .rotate-text {
  position: relative;
  /* width: 100%; */
  height: 100%;
  display: inline-block;
  width: 300px;
  mix-blend-mode: difference;
  overflow: hidden;
  margin-right: -150px;
}

.keep-scrolling .rotate-text text {
  font-family: 'montaga';
  font-size: 1.05em;
}

.keep-scrolling .rotate-text svg {

  position: absolute;
  left: 0;

  -webkit-animation-name: rotate;

          animation-name: rotate;
  -webkit-animation-duration: 8s;
          animation-duration: 8s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
}

@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }

  to {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
}

@keyframes rotate {
  from {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }

  to {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
}

/* ________________ FOCUS BLOCKS __________________ */

.focus .focus-body>* {
  padding: 1.65em;
  padding: var(--padding);
  min-height: 100vh;
}

.focus .focus-body .gallery {
  min-height: unset;
}

.focus .goals, .focus .challenges, .focus .outcomes, .focus .scroller, .focus .text {
  background-image: url(/static/media/paper.ff7c768c.png);
  background-size: 100% 100%;
  background-position: center;
}

.focus .goals, .focus .text {
  display: grid;
}

.focus .text {
  min-height: unset;
}

.focus .info {
  align-self: center;
}

.focus .info>* {
  margin-bottom: 1em;
  margin-bottom: var(--gap);
  max-width: 34em;
}

.focus .info>*:last-child {
  margin-bottom: 0;
}

.focus .goals .images, .focus .text .images, .focus .research .images {
  display: grid;
}

.focus .research ::selection {
  background-color: #121212;
  background-color: var(--colour-black);
  color: #f0a76c;
  color: var(--primary);

}

.focus .research {

  display: grid;
  background-color: #f0a76c;
  background-color: var(--primary);
  --colour-text: var(--colour-black);
  padding-bottom: 0;
  grid-template-rows: auto -webkit-min-content;
  grid-template-rows: auto min-content;
}

.focus .research .info {
  padding-bottom: 1.65em;
  padding-bottom: var(--padding);
}

.focus .research .images div {
  width: calc(100% + calc(1.65em * 2));
  width: calc(100% + calc(var(--padding) * 2));
  margin-left: calc(1.65em * -1);
  margin-left: calc(var(--padding) * -1);
  height: 120vw;
  background-size: cover;
  background-position: center;
  align-self: end;
  background-color: #121212;
  background-color: var(--colour-black);
}

.focus .challenges {
  min-height: unset;
}

.focus .challenges .info {
  display: grid;
  grid-gap: 1em;
  gap: 1em;
  grid-gap: var(--gap);
  gap: var(--gap);
}

.focus .challenges h1 {
  margin-bottom: 1em;
  margin-bottom: var(--gap);
}

.focus .focus-body .scroller {
  width: 100%;
  min-height: unset;
  padding: 0;
  overflow-x: hidden;
}

.focus .focus-body .scroller .video {
  width: 100%;
  display: flex;
  cursor: pointer;
}

.focus .focus-body .scroller .video video {
  width: 100%;
  height: 100%;
}

.focus .scroller figcaption {
  padding: 1em calc(1.65em * 1.7);
  padding: var(--gap) calc(var(--padding) * 1.7);
  opacity: 0.5;
  --content: "proxima nova", sans-serif;
  font-size: 0.6em;
}

.focus .focus-body .gallery {
  padding: 0;
  width: 100%;
  overflow: hidden;
  align-items: flex-start;
}

.focus .focus-body .gallery.spaced {
  background-color: #121212;
  background-color: var(--colour-black);
}

.focus .gallery.spaced .inner-gallery {
  grid-gap: 1em;
  gap: 1em;
  grid-gap: var(--gap);
  gap: var(--gap);
  padding: 1em;
}

.focus .gallery .inner-gallery div {
  width: auto;
  height: 30em;
  flex: 0 0 auto;
}
.focus .gallery.spaced .inner-gallery div {
  border-radius: 1em;
  overflow: hidden; 
}

.focus .gallery .inner-gallery img {
  height: 100%;
  width: unset;
  display: block;
  margin: auto;
}

.focus .inner-gallery {
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow-x: auto;
}

.focus .focus-body .video {
  padding: unset;
  min-height: unset;
}

.focus .video iframe {
  width: 100%;
  aspect-ratio: 16/9;
}

.close-focus-scroll {
  position: absolute;
  z-index: -1;
  background-color: #121212;
  background-color: var(--colour-black);
  display: grid;
  justify-items: center;
  padding-top: calc(1.65em * 2);
  padding-top: calc(var(--padding) * 2);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.close-focus-scroll h3 {
  opacity: 0.3;
}


/* ________________ PROJECTS POP __________________ */

div.all-projects {
  height: 100vh;
}

@media(hover: hover) and (pointer: fine) {

  /* ________________HOVERS__________________ */

  div.fab:hover .hover {
    -webkit-transform: translateY(-50%) scale(1);
            transform: translateY(-50%) scale(1);
    left: -5em;
    opacity: 1;
  }

}
@media screen and (min-width: 32em) {
  p {
    font-size: 1.2em;
  }

  .main-menu .inner-menu {
    display: grid;
    grid-template-rows: -webkit-max-content -webkit-max-content auto -webkit-min-content;
    grid-template-rows: max-content max-content auto min-content;
    grid-gap: calc(var(--padding) * 2);
    gap: calc(var(--padding) * 2);
    height: 100%;
  }

  .main-menu nav img {
    margin-top: 0;

  }

  .focus {
    --padding: 5em;
  }

  .focus .research .images div {
    width: calc(100% + calc(var(--padding) * 2));
    margin-left: calc(var(--padding) * -1);
    height: 30em;
    background-size: cover;
    background-position: center;
  }

  .photograph {
    background-position: center;
    background-size: cover;
    width: 80%;
    max-width: 30em;
    height: 34em;
  }

  .photograph-sub {
    width: 55%;
    top: 28em;
    margin-right: 5vw;
  }

  .photograph-overlap {
    padding: 0;
  }
}

@media screen and (min-width: 60em) {

  button.icon {
    width: 2.5em;
    height: 2.5em;
  }

  nav.topbar {
    grid-template-columns: -webkit-max-content -webkit-min-content;
    grid-template-columns: max-content min-content;
    grid-gap: calc(var(--padding) * 2);
    gap: calc(var(--padding) * 2);
    width: unset;
  }

  .main-menu {
    width: 34vw;
    right: 0;
    min-width: 27em;
    max-width: 32em;
  }

  div.menu.active {
    width: 34vw !important;
    min-width: 27em;
    max-width: 32em;
  }

  .tile.intro, .tile.projects {
    background-size: 50% 100%;
  }


  /* ________________ UTILITY __________________ */

  .photograph {
    background-position: center;
    background-size: cover;
    width: 26vw;
    height: 34vw;
    -webkit-transform: rotate(3deg);
            transform: rotate(3deg);
    margin: 0;
    margin-top: 1.2em;
    max-width: 24em;
    max-height: 30em;
  }

  .photograph-sub {
    width: 65%;
    height: 12vw;
    right: 0;
    margin-right: calc(var(--padding) * -0.5);
    bottom: 0;
    top: unset;
  }

  .photograph-overlap {
    width: unset;
    height: 36vw;
    max-height: 32em;
    align-self: center;
  }

  /* ________________ LAYOUTS __________________ */

  h1 {
    font-size: 2.75em;
  }

  section.tile {
    --padding: 2.75em;
  }

  section.layout {
    display: grid;
    grid-gap: var(--padding);
    gap: var(--padding);
  }

  section.tile.intro {
    padding-top: calc(var(--padding) * 2);
  }

  section.tile.intro .hero {
    font-size: 1em;
    display: grid;
    grid-template-columns: 55% 45%;
  }

  section.tile.intro .hero-type {
    position: relative;
    z-index: 1;
  }

  section.tile.intro .hero-text {
    width: 22em;
  }

  section.tile.intro .hero .hero-type {
    margin-bottom: unset;
    grid-gap: var(--padding);
    gap: var(--padding);
  }

  section.tile.intro .hero-sub {
    max-width: 22em;
  }

  section.tile.intro .hero-sub p {
    padding-right: 0;
  }

  section.tile.intro .shape-break {
    margin-bottom: calc(var(--padding) * 1);
  }

  section.tile.intro img.hero-img {
    width: calc(100% + calc(var(--padding) * 2));
    margin-top: var(--padding);
    margin-right: calc(var(--padding) * -2);
    opacity: 1;
    grid-column: 1;
    grid-row: 1;
  }

  /* __________ PROJECTS _________*/

  section.projects {
    display: grid;
    grid-gap: var(--gap);
    gap: var(--gap);
    padding-top: calc(var(--padding) * 1.2);
  }

  section.projects .project-head {
    position: absolute;
    left: 0;
    bottom: calc(var(--padding) * 1);
    margin-top: unset;
  }

  section.projects .project-head nav img {
    cursor: w-resize;
  }

  section.projects .project-head nav button:last-child img {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    cursor: e-resize;
  }

  section.projects .projects-row-container {
    width: calc(100vw - calc(var(--padding) * 2));
  }

  section.projects .projects-row .project-tile {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: unset;
  }

  section.projects .projects-row .project-tile .project-image {
    background-position: center;
    background-size: cover;
    width: 26vw;
    height: 34vw;
    -webkit-transform: rotate(3deg);
            transform: rotate(3deg);
    margin: 0;
    margin-top: 1.2em;
    max-width: 24em;
    max-height: 30em;
  }

  section.projects .projects-row .project-tile .project-image-sub {
    width: 36%;
    height: 12vw;
    right: unset;
    left: 28%;
  }

  section.projects .projects-row .project-tile .project-image-sub2 {
    display: block;
    margin-right: 14%;
  }

  section.projects .projects-row .project-tile .project-info {
    padding: 0 calc(var(--padding) * 2);
    grid-template-rows: -webkit-max-content -webkit-max-content -webkit-max-content -webkit-min-content;
    grid-template-rows: max-content max-content max-content min-content;
    opacity: 1;
    grid-column: 1;
    grid-row: 1;
  }

  section.projects .projects-row .project-tile .project-info h1 {
    font-size: 2.75em;
    font-weight: 900;
    margin-bottom: 1em;
    cursor: pointer;
  }

  section.projects .projects-row .project-tile .project-info .desc, section.projects .projects-row .project-tile .project-info .extra-info {
    display: block;
    font-size: 1.1em;
    width: 20em;
  }

  section.projects .projects-row .project-tile .project-info .expand-project:hover h3 {
    text-decoration: underline;
  }

  section.projects .projects-row .project-tile .project-meta {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: var(--gap);
    gap: var(--gap);
  }

  section.projects .projects-row .project-tile .project-meta .tags {
    grid-column: span 2;
  }

  section.projects.expand .projects-row {
    margin-top: -9.6em;
    margin-left: calc(-50vw - var(--padding));
    transition: margin var(--time-proExpand);
  }

  section.projects.expand .projects-row .project-tile:not(.off) .project-image {
    width: 50vw;
    height: 100vh;
    max-width: 50vw;
    max-height: 100vh;
  }

  /* ________________ FOCUS __________________ */

  .focus-anim-in {
    position: fixed;
    z-index: var(--z-animOver);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    pointer-events: none;
  }

  .focus {
    background-color: transparent;
    overflow: auto;
    z-index: var(--z-focus);
    position: relative;
    --padding: 2.5em;
  }

  .focus h1 {
    color: var(--colour-black);
    font-size: 2.75em;
    font-weight: 900;
  }

  .focus .close {
    align-self: center;
  }

  .focus .hero-image {
    width: 100%;
    /* margin: 0 calc(var(--padding) * -1); */
    height: 100%;
    background-position: center;
    background-size: cover;
  }

  .focus img {
    width: 100%;
  }

  .focus .focus-hero {
    display: grid;
    grid-template-columns: 1fr 1fr;
    min-height: 100vh;
    grid-template-rows: unset;
    background-color: var(--colour-white);
  }

  .inner-focus {
    position: relative;
  }

  .inner-inner-focus {
    background-color: var(--colour-white);
    display: grid;
  }

  .focus .focus-header {
    grid-template-columns: auto -webkit-min-content;
    grid-template-columns: auto min-content;
    grid-template-rows: -webkit-min-content auto;
    grid-template-rows: min-content auto;
    height: 100%;
    grid-gap: var(--padding);
    gap: var(--padding);
  }

  .focus ul.info-list li {
    display: grid;
    grid-gap: var(--gap);
    gap: var(--gap);
    grid-template-columns: -webkit-max-content auto;
    grid-template-columns: max-content auto;
    padding: calc(var(--gap) / 3) 0;
    text-transform: capitalize;
  }

  .focus .focus-info {
    grid-template-rows: auto auto;
    grid-column: span 2;
    grid-gap: var(--gap) 0;
    gap: var(--gap) 0;
  }

  .focus .focus-body {
    background-color: var(--colour-black);
  }

  .focus .focus-body img {
    margin-right: calc(var(--padding) * -1);
    /* width: calc(100% + var(--padding)); */
  }

  .keep-scrolling {
    justify-items: center;
  }

  .keep-scrolling .rotate-text {
    margin-right: unset;
  }

  /* ________________ FOCUS BLOCKS __________________ */

  .focus .focus-body>* {
    padding: calc(var(--padding) * 2);
  }

  .focus .goals, .focus .text {
    grid-template-columns: 1fr 1fr;
  }

  .focus .info {
    align-self: center;
    padding-right: var(--padding);
  }

  .focus .research {
    grid-template-columns: 60% 40%;
  }

  .focus .research .images div {
    width: calc(100% + calc(var(--padding) * 2));
    height: calc(100% + calc(var(--padding) * 4));
    margin-left: unset;
    margin-right: calc(var(--padding) * -2);
    margin-bottom: calc(var(--padding) * -2);
  }

  .focus .challenges .info {
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: calc(var(--padding) * 2);
    gap: calc(var(--padding) * 2);
  }

  .focus .scroller figcaption {
    padding: var(--gap) calc(var(--padding) * 2);
    opacity: 0.5;
    --content: "proxima nova", sans-serif;
    font-size: 1em;
  }

  .close-focus-scroll {
    position: absolute;
    z-index: -1;
    background-color: var(--colour-black);
    display: grid;
    justify-items: center;
    padding-top: calc(var(--padding) * 2);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .close-focus-scroll h3 {
    opacity: 0.3;
  }


  /* ________________ ME __________________ */

  .tile.me {
    --padding: 4em;
  }

  .me-img {
    margin-top: calc(var(--padding) * -1);
  }

  .me-img img {
    width: 30vw;
  }

  .me .hero-me {
    display: grid;
    grid-template-columns: -webkit-max-content auto;
    grid-template-columns: max-content auto;
    margin: 0 auto;
  }

  .me .hero-text {
    margin: unset;
    padding: unset;
    margin-left: calc(var(--padding) * -2);
    padding-bottom: var(--padding);
    max-width: 30em;
    align-self: flex-end;
    font-size: 1.2vw;
  }

  ul.expand-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    grid-gap: 0 var(--padding);
    gap: 0 var(--padding);
  }

  ul.expand-list> :first-child {
    grid-column: span 2;
  }

  ul.expand-list li {
    border-top: solid 1px var(--gray);
    position: relative;
    padding: var(--padding);
    display: grid;
    padding-bottom: 0;
  }

  ul.expand-list li h6 {
    position: absolute;
    top: var(--gap);
    left: 0;
  }

  ul.expand-list li h3 {
    font-size: 1em;
  }

  ul.expand-list li .expand-info {
    padding-bottom: var(--padding);
    cursor: pointer;
  }

  ul.expand-list li .expand-info h2 {
    display: inline-block;
    border-bottom: 1px transparent solid;
  }

  ul.expand-list li .expand-info:hover h2 {
    border-bottom: 1px black solid;
  }

  ul.expand-list li .expand-body {
    overflow: hidden;
    transition: max-height 0.5s ease;
    max-height: 0;
  }

  ul.expand-list .expand-body article {
    display: grid;
  }

  ul.expand-list li .expand-body.active {
    display: block;
  }

  ul.expand-list li .inner-expand-body {
    padding-bottom: var(--padding);
    display: grid;
    grid-gap: calc(var(--gap) * 1.5);
    gap: calc(var(--gap) * 1.5);
  }

  ul.expand-list li .inner-expand-body .header {
    display: grid;
    grid-template-columns: -webkit-min-content auto;
    grid-template-columns: min-content auto;
    grid-gap: var(--gap);
    gap: var(--gap);
    align-items: center;
    padding-top: var(--gap);
  }

  ul.expand-list li .inner-expand-body .header div, .tile.intro div.shape-break div {
    border: solid 1px var(--accent2);
    width: 0.8em;
    height: 0.8em;
    border-radius: var(--round);
  }

  ul.expand-list li .inner-expand-body>div:first-of-type div, .tile.intro div.shape-break div:first-of-type {
    border-radius: unset;
  }

  ul.expand-list li .inner-expand-body>div:last-of-type div, .tile.intro div.shape-break div:last-of-type {
    border-radius: unset;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    margin-left: 0.2em;
    width: 0.7em;
    height: 0.7em;
  }

}

@media screen and (min-width: 82em) {

  h1 {
    font-size: 3em;
  }

  section.tile {
    --padding: 4em;
  }

  .main-menu .inner-menu {
    grid-gap: calc(var(--padding) * 2.8);
    gap: calc(var(--padding) * 2.8);
  }

  .main-menu .inner-menu>p {
    max-width: 20em;
  }

  section.projects .projects-row .project-tile .project-info h1 {
    font-size: 3.8em;
  }

  section.projects .projects-row .project-tile .project-info .desc {
    font-size: 1.3em;
  }

  section.projects .projects-row .project-tile .project-image-sub2 {
    margin-right: 20%;
  }

  .focus {
    --padding: 4em;
  }

  .focus h1 {
    font-size: 3.5em;
  }

  .focus .focus-info {
    grid-template-columns: 70% 30%;
  }

  .focus .focus-info .project-image-sub2 {
    display: block;
    align-self: center;
    justify-self: right;
  }

}

@media screen and (max-height: 49em) and (max-width: 32em) {
  .main-menu .inner-menu>p:first-of-type {
    display: none;
  }

  section.tile.intro .hero-text h1 {
    font-size: 2em;
  }

  h1 {
    font-size: 1.8em;
  }

  section.projects .projects-row .project-tile .project-info {
    padding: 0 calc(var(--padding) * 1.3);
  }

  section.projects .projects-row .project-tile .project-image {
    height: 30vh;
  }

  section.projects .projects-row .project-tile .project-image-sub {
    width: 45%;
    height: 24vw;
    max-height: 10em;
    max-width: 15em;
  }

  section.projects .projects-row .project-tile .project-images {
    padding-bottom: calc(var(--padding) * 1.8);
  }

  section.projects .project-head {
    padding: 0 calc(var(--padding) * 0.2);
    bottom: 0;
  }

  .app::after {
    left: -40vw;
  }

}
