@media screen and (min-width: 32em) {
  p {
    font-size: 1.2em;
  }

  .main-menu .inner-menu {
    display: grid;
    grid-template-rows: max-content max-content auto min-content;
    gap: calc(var(--padding) * 2);
    height: 100%;
  }

  .main-menu nav img {
    margin-top: 0;

  }

  .focus {
    --padding: 5em;
  }

  .focus .research .images div {
    width: calc(100% + calc(var(--padding) * 2));
    margin-left: calc(var(--padding) * -1);
    height: 30em;
    background-size: cover;
    background-position: center;
  }

  .photograph {
    background-position: center;
    background-size: cover;
    width: 80%;
    max-width: 30em;
    height: 34em;
  }

  .photograph-sub {
    width: 55%;
    top: 28em;
    margin-right: 5vw;
  }

  .photograph-overlap {
    padding: 0;
  }
}

@media screen and (min-width: 60em) {

  button.icon {
    width: 2.5em;
    height: 2.5em;
  }

  nav.topbar {
    grid-template-columns: max-content min-content;
    gap: calc(var(--padding) * 2);
    width: unset;
  }

  .main-menu {
    width: 34vw;
    right: 0;
    min-width: 27em;
    max-width: 32em;
  }

  div.menu.active {
    width: 34vw !important;
    min-width: 27em;
    max-width: 32em;
  }

  .tile.intro, .tile.projects {
    background-size: 50% 100%;
  }


  /* ________________ UTILITY __________________ */

  .photograph {
    background-position: center;
    background-size: cover;
    width: 26vw;
    height: 34vw;
    transform: rotate(3deg);
    margin: 0;
    margin-top: 1.2em;
    max-width: 24em;
    max-height: 30em;
  }

  .photograph-sub {
    width: 65%;
    height: 12vw;
    right: 0;
    margin-right: calc(var(--padding) * -0.5);
    bottom: 0;
    top: unset;
  }

  .photograph-overlap {
    width: unset;
    height: 36vw;
    max-height: 32em;
    align-self: center;
  }

  /* ________________ LAYOUTS __________________ */

  h1 {
    font-size: 2.75em;
  }

  section.tile {
    --padding: 2.75em;
  }

  section.layout {
    display: grid;
    gap: var(--padding);
  }

  section.tile.intro {
    padding-top: calc(var(--padding) * 2);
  }

  section.tile.intro .hero {
    font-size: 1em;
    display: grid;
    grid-template-columns: 55% 45%;
  }

  section.tile.intro .hero-type {
    position: relative;
    z-index: 1;
  }

  section.tile.intro .hero-text {
    width: 22em;
  }

  section.tile.intro .hero .hero-type {
    margin-bottom: unset;
    gap: var(--padding);
  }

  section.tile.intro .hero-sub {
    max-width: 22em;
  }

  section.tile.intro .hero-sub p {
    padding-right: 0;
  }

  section.tile.intro .shape-break {
    margin-bottom: calc(var(--padding) * 1);
  }

  section.tile.intro img.hero-img {
    width: calc(100% + calc(var(--padding) * 2));
    margin-top: var(--padding);
    margin-right: calc(var(--padding) * -2);
    opacity: 1;
    grid-column: 1;
    grid-row: 1;
  }

  /* __________ PROJECTS _________*/

  section.projects {
    display: grid;
    gap: var(--gap);
    padding-top: calc(var(--padding) * 1.2);
  }

  section.projects .project-head {
    position: absolute;
    left: 0;
    bottom: calc(var(--padding) * 1);
    margin-top: unset;
  }

  section.projects .project-head nav img {
    cursor: w-resize;
  }

  section.projects .project-head nav button:last-child img {
    transform: rotate(180deg);
    cursor: e-resize;
  }

  section.projects .projects-row-container {
    width: calc(100vw - calc(var(--padding) * 2));
  }

  section.projects .projects-row .project-tile {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: unset;
  }

  section.projects .projects-row .project-tile .project-image {
    background-position: center;
    background-size: cover;
    width: 26vw;
    height: 34vw;
    transform: rotate(3deg);
    margin: 0;
    margin-top: 1.2em;
    max-width: 24em;
    max-height: 30em;
  }

  section.projects .projects-row .project-tile .project-image-sub {
    width: 36%;
    height: 12vw;
    right: unset;
    left: 28%;
  }

  section.projects .projects-row .project-tile .project-image-sub2 {
    display: block;
    margin-right: 14%;
  }

  section.projects .projects-row .project-tile .project-info {
    padding: 0 calc(var(--padding) * 2);
    grid-template-rows: max-content max-content max-content min-content;
    opacity: 1;
    grid-column: 1;
    grid-row: 1;
  }

  section.projects .projects-row .project-tile .project-info h1 {
    font-size: 2.75em;
    font-weight: 900;
    margin-bottom: 1em;
    cursor: pointer;
  }

  section.projects .projects-row .project-tile .project-info .desc, section.projects .projects-row .project-tile .project-info .extra-info {
    display: block;
    font-size: 1.1em;
    width: 20em;
  }

  section.projects .projects-row .project-tile .project-info .expand-project:hover h3 {
    text-decoration: underline;
  }

  section.projects .projects-row .project-tile .project-meta {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: var(--gap);
  }

  section.projects .projects-row .project-tile .project-meta .tags {
    grid-column: span 2;
  }

  section.projects.expand .projects-row {
    margin-top: -9.6em;
    margin-left: calc(-50vw - var(--padding));
    transition: margin var(--time-proExpand);
  }

  section.projects.expand .projects-row .project-tile:not(.off) .project-image {
    width: 50vw;
    height: 100vh;
    max-width: 50vw;
    max-height: 100vh;
  }

  /* ________________ FOCUS __________________ */

  .focus-anim-in {
    position: fixed;
    z-index: var(--z-animOver);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    pointer-events: none;
  }

  .focus {
    background-color: transparent;
    overflow: auto;
    z-index: var(--z-focus);
    position: relative;
    --padding: 2.5em;
  }

  .focus h1 {
    color: var(--colour-black);
    font-size: 2.75em;
    font-weight: 900;
  }

  .focus .close {
    align-self: center;
  }

  .focus .hero-image {
    width: 100%;
    /* margin: 0 calc(var(--padding) * -1); */
    height: 100%;
    background-position: center;
    background-size: cover;
  }

  .focus img {
    width: 100%;
  }

  .focus .focus-hero {
    display: grid;
    grid-template-columns: 1fr 1fr;
    min-height: 100vh;
    grid-template-rows: unset;
    background-color: var(--colour-white);
  }

  .inner-focus {
    position: relative;
  }

  .inner-inner-focus {
    background-color: var(--colour-white);
    display: grid;
  }

  .focus .focus-header {
    grid-template-columns: auto min-content;
    grid-template-rows: min-content auto;
    height: 100%;
    gap: var(--padding);
  }

  .focus ul.info-list li {
    display: grid;
    gap: var(--gap);
    grid-template-columns: max-content auto;
    padding: calc(var(--gap) / 3) 0;
    text-transform: capitalize;
  }

  .focus .focus-info {
    grid-template-rows: auto auto;
    grid-column: span 2;
    gap: var(--gap) 0;
  }

  .focus .focus-body {
    background-color: var(--colour-black);
  }

  .focus .focus-body img {
    margin-right: calc(var(--padding) * -1);
    /* width: calc(100% + var(--padding)); */
  }

  .keep-scrolling {
    justify-items: center;
  }

  .keep-scrolling .rotate-text {
    margin-right: unset;
  }

  /* ________________ FOCUS BLOCKS __________________ */

  .focus .focus-body>* {
    padding: calc(var(--padding) * 2);
  }

  .focus .goals, .focus .text {
    grid-template-columns: 1fr 1fr;
  }

  .focus .info {
    align-self: center;
    padding-right: var(--padding);
  }

  .focus .research {
    grid-template-columns: 60% 40%;
  }

  .focus .research .images div {
    width: calc(100% + calc(var(--padding) * 2));
    height: calc(100% + calc(var(--padding) * 4));
    margin-left: unset;
    margin-right: calc(var(--padding) * -2);
    margin-bottom: calc(var(--padding) * -2);
  }

  .focus .challenges .info {
    grid-template-columns: 1fr 1fr 1fr;
    gap: calc(var(--padding) * 2);
  }

  .focus .scroller figcaption {
    padding: var(--gap) calc(var(--padding) * 2);
    opacity: 0.5;
    --content: "proxima nova", sans-serif;
    font-size: 1em;
  }

  .close-focus-scroll {
    position: absolute;
    z-index: -1;
    background-color: var(--colour-black);
    display: grid;
    justify-items: center;
    padding-top: calc(var(--padding) * 2);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .close-focus-scroll h3 {
    opacity: 0.3;
  }


  /* ________________ ME __________________ */

  .tile.me {
    --padding: 4em;
  }

  .me-img {
    margin-top: calc(var(--padding) * -1);
  }

  .me-img img {
    width: 30vw;
  }

  .me .hero-me {
    display: grid;
    grid-template-columns: max-content auto;
    margin: 0 auto;
  }

  .me .hero-text {
    margin: unset;
    padding: unset;
    margin-left: calc(var(--padding) * -2);
    padding-bottom: var(--padding);
    max-width: 30em;
    align-self: flex-end;
    font-size: 1.2vw;
  }

  ul.expand-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    gap: 0 var(--padding);
  }

  ul.expand-list> :first-child {
    grid-column: span 2;
  }

  ul.expand-list li {
    border-top: solid 1px var(--gray);
    position: relative;
    padding: var(--padding);
    display: grid;
    padding-bottom: 0;
  }

  ul.expand-list li h6 {
    position: absolute;
    top: var(--gap);
    left: 0;
  }

  ul.expand-list li h3 {
    font-size: 1em;
  }

  ul.expand-list li .expand-info {
    padding-bottom: var(--padding);
    cursor: pointer;
  }

  ul.expand-list li .expand-info h2 {
    display: inline-block;
    border-bottom: 1px transparent solid;
  }

  ul.expand-list li .expand-info:hover h2 {
    border-bottom: 1px black solid;
  }

  ul.expand-list li .expand-body {
    overflow: hidden;
    transition: max-height 0.5s ease;
    max-height: 0;
  }

  ul.expand-list .expand-body article {
    display: grid;
  }

  ul.expand-list li .expand-body.active {
    display: block;
  }

  ul.expand-list li .inner-expand-body {
    padding-bottom: var(--padding);
    display: grid;
    gap: calc(var(--gap) * 1.5);
  }

  ul.expand-list li .inner-expand-body .header {
    display: grid;
    grid-template-columns: min-content auto;
    gap: var(--gap);
    align-items: center;
    padding-top: var(--gap);
  }

  ul.expand-list li .inner-expand-body .header div, .tile.intro div.shape-break div {
    border: solid 1px var(--accent2);
    width: 0.8em;
    height: 0.8em;
    border-radius: var(--round);
  }

  ul.expand-list li .inner-expand-body>div:first-of-type div, .tile.intro div.shape-break div:first-of-type {
    border-radius: unset;
  }

  ul.expand-list li .inner-expand-body>div:last-of-type div, .tile.intro div.shape-break div:last-of-type {
    border-radius: unset;
    transform: rotate(45deg);
    margin-left: 0.2em;
    width: 0.7em;
    height: 0.7em;
  }

}

@media screen and (min-width: 82em) {

  h1 {
    font-size: 3em;
  }

  section.tile {
    --padding: 4em;
  }

  .main-menu .inner-menu {
    gap: calc(var(--padding) * 2.8);
  }

  .main-menu .inner-menu>p {
    max-width: 20em;
  }

  section.projects .projects-row .project-tile .project-info h1 {
    font-size: 3.8em;
  }

  section.projects .projects-row .project-tile .project-info .desc {
    font-size: 1.3em;
  }

  section.projects .projects-row .project-tile .project-image-sub2 {
    margin-right: 20%;
  }

  .focus {
    --padding: 4em;
  }

  .focus h1 {
    font-size: 3.5em;
  }

  .focus .focus-info {
    grid-template-columns: 70% 30%;
  }

  .focus .focus-info .project-image-sub2 {
    display: block;
    align-self: center;
    justify-self: right;
  }

}

@media screen and (max-height: 49em) and (max-width: 32em) {
  .main-menu .inner-menu>p:first-of-type {
    display: none;
  }

  section.tile.intro .hero-text h1 {
    font-size: 2em;
  }

  h1 {
    font-size: 1.8em;
  }

  section.projects .projects-row .project-tile .project-info {
    padding: 0 calc(var(--padding) * 1.3);
  }

  section.projects .projects-row .project-tile .project-image {
    height: 30vh;
  }

  section.projects .projects-row .project-tile .project-image-sub {
    width: 45%;
    height: 24vw;
    max-height: 10em;
    max-width: 15em;
  }

  section.projects .projects-row .project-tile .project-images {
    padding-bottom: calc(var(--padding) * 1.8);
  }

  section.projects .project-head {
    padding: 0 calc(var(--padding) * 0.2);
    bottom: 0;
  }

  .app::after {
    left: -40vw;
  }

}